import trustCard from '../../public/static/img/pages/home/2-card-trust.png';
import Image from '../Image';

import SC from './homeElements';

const TrustCardSection = () => {
  return (
    <SC.TrustCardBox>
      <SC.StyledContain className="text-center">
        <SC.Row flexDirection={['column-reverse', 'row']}>
          <SC.Col flexBasis={['0', '40%']} textAlign="left" alignSelf="center">
            <p style={{ fontSize: '1.75rem' }}>
              <strong>ALWAYS MORE</strong> Linkpoints on essentials at FairPrice Group
            </p>
            <p>
              Earn up to 21% savings through Trust card when you shop at FairPrice Group, including
              FairPrice Supermarket, FairPrice Finest, FairPrice Xtra, FairPrice Online, FairPrice
              Xpress, Kopitiam, Unity, and Cheers.
            </p>
          </SC.Col>
          <SC.Col flexBasis={['0', '60%']} alignSelf="center">
            <Image
              alt="Trust Card"
              src={trustCard}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw,33vw"
            />
          </SC.Col>
        </SC.Row>
      </SC.StyledContain>
    </SC.TrustCardBox>
  );
};

export default TrustCardSection;
